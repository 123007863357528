<template >
  <v-expansion-panels multiple v-model="panels">
    <v-expansion-panel>
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        Generali
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <v-text-field  :label="tLabelRequired('numero_traccia')"  v-model.trim="item.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice"  clearable/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select :label="tLabelRequired('provenienza')" v-model="slotProps.data.nodoPartenzaId" @change="nodoPartenzaId=slotProps.data.nodoPartenzaId" :items="listNodi" item-value="id" item-text="codice"  :rules="[rules.required]" :error-messages="slotProps.errors.nodoPartenza" clearable/>
          </v-col>
          <v-col cols="6">
            <v-select :label="tLabelRequired('destinazione')" v-model="slotProps.data.nodoArrivoId" @change="nodoArrivoId=slotProps.data.nodoArrivoId" :items="listNodi" item-value="id" item-text="codice"  :rules="[rules.required]" :error-messages="slotProps.errors.nodoArrivo"  clearable/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <TimeAdapter :label="item.nodoPartenza&&item.nodoPartenza.isNodoTerminal?tLabelRequired('ora_partenza'):tLabel('ora_partenza')" v-model="slotProps.data.oraPartenza" :rules="item.nodoPartenza&&item.nodoPartenza.isNodoTerminal?[rules.required]:[]" :error-messages="slotProps.errors.oraPartenza" />
          </v-col>
          <v-col cols="6">
            <TimeAdapter :label="item.nodoArrivo&&item.nodoArrivo.isNodoTerminal?tLabelRequired('ora_arrivo'):tLabel('ora_arrivo')" v-model="slotProps.data.oraArrivo" :rules="item.nodoArrivo&&item.nodoArrivo.isNodoTerminal?[rules.required]:[]" :error-messages="slotProps.errors.oraArrivo" />
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="6">
            <CalendarFilter :label="tLabel('inizio_validita')" v-model="slotProps.data.inizioValidita"  :error-messages="slotProps.errors.inizioValidita" />
          </v-col>
          <v-col cols="6">
            <CalendarFilter :label="tLabel('fine_validita')" v-model="slotProps.data.fineValidita"  :error-messages="slotProps.errors.fineValidita" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-checkbox :label="tLabel('fuori_sagoma_TES')" v-model="slotProps.data.fuoriSagoma" :error-messages="slotProps.errors.fuoriSagoma"/>
          </v-col>
          <v-col cols="6">
            <v-checkbox :label="tLabel('merce_pericolosa_mp')" v-model="slotProps.data.mercePericolosa" :error-messages="slotProps.errors.mercePericolosa"/>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

     <v-expansion-panel>
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        Descrizione
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <v-textarea :label="tLabel('descrizione')" v-model="slotProps.data.descrizione" :error-messages="slotProps.errors.descrizione" auto-grow rows="2"/>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        {{tLabelRequired("Periodicit&agrave;")}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="1" v-for="giorno in listGiorni" :key="giorno.id" >
            <v-checkbox :label="giorno.code" :value="giorno.id"  v-model="periodicita" :rules="[rules.required]" :error-messages="slotProps.errors.periodicita"/>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        Soggetti
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="6">
            <v-select :label="tLabel('vettore')" v-model="slotProps.data.vettoreId" :items="listVettori" item-value="id" item-text="codice"  />
          </v-col>
          <v-col cols="6">
            <v-select :label="tLabel('mto')" v-model="slotProps.data.mtoId" :items="listMTO" item-value="id" item-text="codice"  />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-if="item.isExport" >
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        Legamenti partenze
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="10">
            <v-select :label="tLabel('legamenti')" v-model="legamentoTracciaImport" :items="listTracceImport"  return-object item-value="id" item-text="label"
              no-data-text="Nessun legamento disponibile" />
          </v-col>
          <v-col cols="2">
            <v-btn  @click="addLegamentoImport()" :disabled="legamentoTracciaImport==null" >Aggiungi</v-btn>
          </v-col>
        </v-row>
        <v-row>
           <v-card 
                v-for="legamento in item.listLegamentiImport" 
                :key="legamento.id"
                style="width:100%;" 
              >
                <v-card-text> 
                    <v-row>
                        <v-col cols="7">
                        <span>
                         {{(legamento.tracciaImport ? legamento.tracciaImport.label +"; Partenza: " : "")}}  {{ (legamento.tracciaImport ? legamento.tracciaImport.oraPartenza : "") | formatTimeFromDate }}  
                  
                        </span>
                        </v-col>
                        <v-col cols="2">
                            <v-select :label="tLabel('arrivo')"  
                                v-model="legamento.giornoArrivo"
                                :items="selectableDays"
                                item-value="code"
                                item-text="code" 
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-select :label="tLabel('partenza')"  
                                v-model="legamento.giornoPartenza"
                                :items="listGiorni"
                                item-value="code"
                                item-text="code" 
                            />
                        </v-col>
                        <v-col cols="1">
                        <v-btn @click="removeLegamentoImport(legamento)" icon title="Elimina">
                            <v-icon color="primary" medium>mdi-delete</v-icon>                    
                        </v-btn>
                        </v-col>

                    </v-row>
                </v-card-text>
              </v-card>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-if="item.isImport" >
      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
        Legamenti arrivo
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="10">
            <v-select :label="tLabel('legamenti')" v-model="legamentoTracciaExport" :items="listTracceExport" return-object item-value="id" item-text="label" />
          </v-col>
          <v-col cols="2">
            <v-btn  @click="addLegamentoExport()" :disabled="legamentoTracciaExport==null">Aggiungi</v-btn>
          </v-col>
        </v-row>
        <v-row>
           <v-card 
                v-for="legamento in item.listLegamentiExport" 
                :key="legamento.id"
                style="width:100%;"
              >
                <v-card-text >
                    <v-row>
                        <v-col cols="7">
                            <span>
                               {{(legamento.tracciaExport ? legamento.tracciaExport.label + "; Partenza: " : "")}}  {{(legamento.tracciaExport ? legamento.tracciaExport.oraPartenza : "") | formatTimeFromDate }} 
                            </span>
                        </v-col>
                        <v-col cols="2">
                            <v-select :label="tLabelRequired('partenza')"  
                                v-model="legamento.giornoPartenza"
                                :items="listGiorni"
                                item-value="code"
                                item-text="code" 
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-select :label="tLabelRequired('arrivo')" 
                                v-model="legamento.giornoArrivo"
                                :items="selectableDays"
                                item-value="code"
                                item-text="code"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="1">
                            <v-btn @click="removeLegamentoExport(legamento)" icon title="Elimina">
                                <v-icon color="primary" medium>mdi-delete</v-icon>                    
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

  </v-expansion-panels>
  

</template>

<script>
import RailTosService from '../../service/tos/RailTosService';
import ToastMixin from '../../mixins/ToastMixin';
import CalendarFilter from '../../components/anagrafiche/CalendarFilter';
import TimeAdapter from '../../components/anagrafiche/TimeAdapter';
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: 'EditTraccia',
  components: {
    TimeAdapter,CalendarFilter,
  },
  data() {
    return {
      listVettori: [],
      listMTO: [],
      listTracceExport: [],
      listTracceImport: [],
      vettoreId: undefined,
      mtoId: undefined,
      nodoPartenzaId: undefined,
      nodoArrivoId: undefined,
      listGiorni: [
        { id: '1', code: 'LU' },
        { id: '2', code: 'MA' },
        { id: '3', code: 'ME' },
        { id: '4', code: 'GI' },
        { id: '5', code: 'VE' },
        { id: '6', code: 'SA' },
        { id: '7', code: 'DO' }
      ],
      booleanDDOptions: [
        { label: 'SI', value: true },
        { label: 'NO', value: false },
      ],
      periodicita: [],
      legamentoTracciaImport: null,
      legamentoTracciaExport: null,
      panels: [0,1,2,3,4],
    };
  },

  service: null,
  props: {
        slotProps: {
            type: Object,
            required: true
        },
          listNodi: {
            type: Array,
            default: () => []
        }
    },
  mixins: [ToastMixin, FormatsMixins],
  created() {
    this.service = new RailTosService('tracce');
    if(!this.item.periodicita) {
        this.item.periodicita = '';
    }
    if(this.item.periodicita){
      this.item.periodicita.split('').forEach(p => {
        this.periodicita.push(
          this._.find(this.listGiorni, g => g.id === p.toString()));
      });
      this.periodicita = this.item.periodicita.split('');
      this.adjustTraccia(this.item);
    }
    if(this.item.vettoreId) {
      this.vettoreId = this.item.vettoreId;
    }
    if(this.item.mtoId) {
      this.mtoId = this.item.mtoId;
    }
    if(this.item.nodoPartenzaId) {
      this.nodoPartenzaId = this.item.nodoPartenzaId;
    }
    if(this.item.nodoArrivoId) {
      this.nodoArrivoId = this.item.nodoArrivoId;
    }
  },
  async mounted() {
    await this.retrieveLists();    
  },
  computed: {
    item: {
        get: function(){
            return this.slotProps.data
        },
        set: function(value) {
            this.slotProps.data=value;
        },
    },
    selectableDays(){
      const selectableDays = [];
      if(this.item.periodicita){
        this.item.periodicita.split('').forEach(p => {
          selectableDays.push(
            this._.find(this.listGiorni, g => g.id === p.toString()));
        });               
      }
      return selectableDays; 
    },
  },
  watch: {
    periodicita: function (val) {
      this.item.periodicita = val.join("");
    },
    nodoPartenzaId: function (val) {
      this.item.nodoPartenzaId = val;
      this.item.nodoPartenza = this._.find(this.listNodi, n => n.id === val);
      if(!this.item.nodoPartenza.isNodoTerminal) {
        delete this.item.listLegamentiExport;
      } 
    },
    nodoArrivoId: function(val) {
      this.item.nodoArrivoId = val;
      this.item.nodoArrivo = this._.find(this.listNodi, n => n.id === val);
      if(!this.item.nodoArrivo.isNodoTerminal) {        
        delete this.item.listLegamentiImport;
      }
    },
    vettoreId: function (val) {
      this.item.vettoreId = val;
      if(this.listVettori && this.listVettori!==null && this.listVettori.length > 0) {
        this.item.vettore = this._.find(this.listVettori, v => v.id === val);
      }      
    },
    mtoId: function (val) {
      this.item.mtoId = val;
      if(this.listMTO && this.listMTO!==null && this.listMTO.length > 0) {
        this.item.mto = this._.find(this.listMTO, m => m.id === val);
      }      
    }   
  },
  methods: {
    adjustTraccia(traccia) {
      if(traccia.oraPartenza) {
        traccia.oraPartenza = new Date(traccia.oraPartenza).toISOString(true);
      }
      if(traccia.oraArrivo) {
        traccia.oraArrivo = new Date(traccia.oraArrivo).toISOString(true);
      }
      if(traccia.inizioValidita) {
        traccia.inizioValidita = new Date(traccia.inizioValidita);
      }
      if(traccia.fineValidita) {
        traccia.fineValidita = new Date(traccia.fineValidita);
      }
      if (traccia.listMute) {
        delete traccia.listMute;
      }
      if (traccia.listLegamentiImport) {
        traccia.listLegamentiImport.forEach(legamento => {
          this.adjustTracciaLegamento(legamento);          
        });
      }
      if (traccia.listLegamentiExport) {
        traccia.listLegamentiExport.forEach(legamento => {
          this.adjustTracciaLegamento(legamento);          
        });
      }
    },
    async adjustTracciaLegamento(legamento){
      if (legamento.tracciaExportId) {
        legamento.tracciaExport = await this.$api.get(
              this.$apiConfiguration.BASE_PATH +"tracce/" +legamento.tracciaExportId
         );
      }

      if (legamento.tracciaImportId) {
        console.log("legamento.tracciaImport: " +legamento.tracciaImport);
        legamento.tracciaImport = await this.$api.get(
              this.$apiConfiguration.BASE_PATH +"tracce/" +legamento.tracciaImportId
         );
      }
    },
    async retrieveLists() {
      this.listVettori = await this.$api.trainData.getVettoriFerroviari();
      this.listMTO = await this.$api.trainData.getMTO();
      this.listTracceExport = [];
      this.listTracceImport = [];
      const mapNodiTerminal={};
      this.listNodi.filter(n=> n.isNodoTerminal).forEach(n=> mapNodiTerminal[n.codice]=true);

      const listTracce = await this.$api.trainData.getTracce();
      listTracce.forEach(t => {
        if(t.listLegamentiImport) delete t.listLegamentiImport;
        if(t.listLegamentiExport) delete t.listLegamentiExport;
        this.adjustTraccia(t);
        if(mapNodiTerminal[t.nodoArrivo.codice]){
          this.listTracceExport.push(t);
        } else {
          this.listTracceImport.push(t);
        }
      });
    },
    addLegamentoImport() {
      if(!this.legamentoTracciaImport){
        this.showError('Selezionare una traccia da aggiungere');
        return;
      }
      console.log("Add legamento import", this.legamentoTracciaImport);
      
      if(!Array.isArray(this.item.listLegamentiImport)){
        this.item.listLegamentiImport = [];
      }

      const pTraccia = Object.assign({}, this.item);
      pTraccia.nodoArrivo = Object.assign({}, pTraccia.nodoArrivo);
      pTraccia.nodoPartenza = Object.assign({}, pTraccia.nodoPartenza);
      pTraccia.vettore = Object.assign({}, pTraccia.vettore);
      pTraccia.mto = Object.assign({}, pTraccia.mto);
      if(pTraccia.listLegamentiImport) delete pTraccia.listLegamentiImport;
      if(pTraccia.listLegamentiExport) delete pTraccia.listLegamentiExport;

      
      this.item.listLegamentiImport.push({
        id: null,
        giornoArrivo: '',
        giornoPartenza: '',
        tracciaExport: pTraccia,
        tracciaExportId: this.item.id,
        tracciaImport: Object.assign({}, this.legamentoTracciaImport),
        tracciaImportId: this.legamentoTracciaImport.id,
      });
      
      this.item = Object.assign({}, this.item);
      this.$emit('itemUpdated', this.item);
    },
    addLegamentoExport() {
      if(!this.legamentoTracciaExport) {
        this.showError('Selezionare una traccia da aggiungere');
        return;
      }

      if(!Array.isArray(this.item.listLegamentiExport)) {
        this.item.listLegamentiExport = [];
      }

      const pTraccia = Object.assign({}, this.item);
      pTraccia.nodoArrivo = Object.assign({}, pTraccia.nodoArrivo);
      pTraccia.nodoPartenza = Object.assign({}, pTraccia.nodoPartenza);
      pTraccia.vettore = Object.assign({}, pTraccia.vettore);
      pTraccia.mto = Object.assign({}, pTraccia.mto);
      if(pTraccia.listLegamentiImport) delete pTraccia.listLegamentiImport;
      if(pTraccia.listLegamentiExport) delete pTraccia.listLegamentiExport;

      this.item.listLegamentiExport.push({
        id: null,
        giornoArrivo: '',
        giornoPartenza: '',
        tracciaExport: Object.assign({}, this.legamentoTracciaExport),
        tracciaExportId: this.legamentoTracciaExport.id,
        tracciaImport: pTraccia,
        tracciaImportId: this.item.id,
      }); 

      this.item = Object.assign({}, this.item);
      this.$emit('itemUpdated', this.item);
    },
    removeLegamentoImport(legamento) {
      this.item.listLegamentiImport = this._.reject(this.item.listLegamentiImport, el => el === legamento);
    },
    removeLegamentoExport(legamento) {
      this.item.listLegamentiExport = this._.reject(this.item.listLegamentiExport, el => el === legamento);
    },
  },
};
</script>

<style scoped>
.edit-tracce-cntr{
  height: 800;
  overflow: auto;
}
</style>